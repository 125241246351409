<template>
  <svg class="icon-sort-default" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_897_32438" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_897_32438)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2052 17.9534C14.2052 17.9534 12.4444 16.1922 12.4416 16.1895C12.2074 15.9551 11.8274 15.9551 11.5929 16.1895C11.3585 16.4239 11.3585 16.8039 11.5929 17.0381C11.5949 17.0406 12.9481 18.3932 14.3791 19.8242C14.6129 20.0586 14.993 20.0586 15.2274 19.8242C16.5956 18.456 17.7983 17.2533 18.0101 17.0412C18.2448 16.8067 18.2448 16.4267 18.0101 16.1928C17.7757 15.9584 17.3957 15.9584 17.1618 16.1928L15.4054 17.9486V8.60016C15.4054 8.44081 15.3423 8.28845 15.2299 8.17571C15.1172 8.06325 14.9645 8.00019 14.8054 8.00019C14.6461 8.00019 14.4937 8.06325 14.381 8.17571C14.2685 8.28845 14.2052 8.44081 14.2052 8.60016L14.2052 17.9534ZM8.98071 6.04665L10.7444 7.81004C10.9791 8.04445 11.3586 8.04445 11.593 7.81004C11.8274 7.57564 11.8274 7.19611 11.593 6.96171L8.80747 4.17559C8.57306 3.94147 8.19298 3.94147 7.95856 4.17559C6.59065 5.54379 5.38819 6.74625 5.1758 6.95835C4.9414 7.19276 4.9414 7.57284 5.1758 7.80726C5.41021 8.04138 5.79029 8.04138 6.02471 7.80726L7.78053 6.05115V15.3996C7.78053 15.5587 7.8436 15.7116 7.95662 15.8241C8.06908 15.9363 8.22144 15.9996 8.38051 15.9996C8.53985 15.9996 8.69249 15.9363 8.80495 15.8241C8.91741 15.7116 8.98076 15.5587 8.98076 15.3996L8.98071 6.04665Z" fill="#0D092E"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconSortDefault"
}
</script>

<style lang="scss" scoped>

</style>